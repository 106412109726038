<template>
    <!-- begin:Content -->
    <div class="content d-flex flex-column flex-column-fluid pt-0" id="kt_content">
        <!-- <Subheader /> -->
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container container-xl">
                <!--begin::Profile Personal Information-->
                <div class="d-flex flex-row justify-content-center">
                    <!--begin::Content-->
                    <div class="flex-row-auto offcanvas-mobile w-550px w-xxl-750px">
                        <!--begin::Card-->
                        <div class="card card-custom card-stretch">
                            <!--begin::Header-->
                            <div class="card-header py-3">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">
                                        Informatii personale
                                    </h3>
                                    <span
                                        class="text-muted font-weight-bold font-size-sm mt-1"
                                        >Modifica informatiile personale</span
                                    >
                                </div>
                                <div class="card-toolbar">
                                    <button
                                        class="btn btn-success mr-2"
                                        @click="onSubmit"
                                    >
                                        Salveaza
                                    </button>
                                    <button
                                        type="reset"
                                        class="btn btn-secondary"
                                        @click="onReset();addSpinner($event.target,300);"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Form-->
                            <form class="form">
                                <!--begin::Body-->
                                <div class="card-body">
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h5 class="font-weight-bold mb-6">
                                                Date Agent
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Avatar</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <div
                                                class="image-input image-input-outline"
                                                id="kt_profile_avatar"
                                                :style="{
                                                    backgroundImage: `url(${photo_url})`,
                                                }"
                                                :class="
                                                    inputChanged
                                                        ? 'image-input-changed'
                                                        : ''
                                                "
                                            >
                                                <div
                                                    class="image-input-wrapper"
                                                    :style="{
                                                        backgroundImage: `url(${photo_url})`,
                                                    }"
                                                ></div>
                                                <label
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title=""
                                                    data-original-title="Change avatar"
                                                >
                                                    <i
                                                        class="fa fa-pen icon-sm text-muted"
                                                    ></i>
                                                    <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        @change="uploadFile"
                                                        ref="file"
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="profile_avatar_remove"
                                                    />
                                                </label>
                                                <span
                                                    class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title=""
                                                    data-original-title="Cancel avatar"
                                                    @click="resetUpload"
                                                >
                                                    <i
                                                        class="ki ki-bold-close icon-xs text-muted"
                                                    ></i>
                                                </span>
                                            </div>
                                            <span class="form-text text-muted"
                                                >Va fi redimensionata la 50x50. PNG, JPG, JPEG acceptate.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Prenume</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <input
                                                class="form-control form-control-lg form-control-solid"
                                                type="text"
                                                v-model="form.firstName"
                                                name="firstName"
                                                style=""
                                            />
                                            <span class="form-text text-primary"
                                                >Acesta v-a aparea in semnatura.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Nume</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <input
                                                class="form-control form-control-lg form-control-solid"
                                                type="text"
                                                v-model="form.lastName"
                                                name="lastName"
                                            />
                                            <span class="form-text text-primary"
                                                >Acesta v-a aparea in semnatura.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Functie</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <input
                                                class="form-control form-control-lg form-control-solid"
                                                type="text"
                                                v-model="form.jobTitle"
                                                name="jobTitle"
                                            />
                                            <span class="form-text text-primary"
                                                >Acesta v-a aparea in semnatura.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >NickName</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <input
                                                class="form-control form-control-lg form-control-solid"
                                                type="text"
                                                v-model="form.nickName"
                                                name="nickName"
                                            />
                                            <span class="form-text text-muted"
                                                >Pentru o afisare mai sccurta in lista de
                                                utilizatori.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Nr. tel</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <div
                                                class="input-group input-group-lg input-group-solid"
                                            >
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="la la-phone"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    class="form-control form-control-lg form-control-solid"
                                                    v-model="form.phone"
                                                    name="phone"
                                                    placeholder="Phone"
                                                />
                                            </div>
                                            <span class="form-text text-primary"
                                                >Acesta v-a aparea in semnatura.</span
                                            >
                                            <span class="form-text text-muted"
                                                >Daca se doreste sa nu apara in semnatura
                                                lasati-l gol.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label"
                                            >Email</label
                                        >
                                        <div class="col-lg-9 col-xl-6">
                                            <div
                                                class="input-group input-group-lg input-group-solid"
                                            >
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="la la-at"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    class="form-control form-control-lg form-control-solid"
                                                    name="email"
                                                    placeholder="Email"
                                                    v-model="form.email"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Body-->
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Card-->
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Profile Personal Information-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
    <!-- end:Content -->
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
    name: "UsersEditProfileCard",
    props: ["user"],
    components: {},
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                jobTitle: "",
                nickName: "",
                phone: "0377101500",
                email: "",
                status: true,
                attachment_id: null,
            },
            photo_url:"",
            inputChanged: false,
        };
    },
    mounted() {},
    computed: {},
    methods: {
        ...mapActions("users", ["getUsersData"]),
        onSubmit(event) {
            event.preventDefault();
            this.addSpinner(event.target);
            let data = this.form;
            this.$store.dispatch("users/addUser", { data }).then((response) => {
                if (response.id) {
                    this.$router.push({
                        name: "EditUserDepartaments",
                        params: { userId: response.id },
                    });
                }
                this.killSpinner(event.target);
            });
            this.inputChanged = false;
        },
        onReset() {
            // Reset our form values
            this.getUsersData(this.$route.params.userId).then((response) => {
                this.form = {
                    firstName: "",
                    lastName: "",
                    jobTitle: "",
                    nickName: "",
                    phone: "0377101500",
                    email: "",
                    status: true,
                    attachment_id: null,
                };
                this.photo_url = "";
            });
        },
        resetUpload() {
            this.photo_url = this.user.photo_url;
            this.inputChanged = false;
            this.form.attachment_id = null;
        },
        uploadFile() {
            if(this.$refs.file.files) {
                var file = this.$refs.file.files[0];
                ApiService.postAttachments("attachments", file).then((response) => {
                    if(response) {
                        this.photo_url = response.fileUrl;
                        this.form.attachment_id = response.id;
                        this.inputChanged = true;
                    }
                });
            }
        },
    },
    created() {
        // this.onReset();
    },
};
</script>
